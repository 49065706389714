import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAuthenticationStore } from '@/stores/authentication'


// TODO add i18n for displayName metadata.
export const sections = [
  {
    path: "/Users",
    name: 'users',
    component: () => import('@/components/layout/Parent.vue'),
    meta: {
      displayName: "navigation.userManagement",
      icon: "$accountGroup",
      groups: ["super"]
    },
    children: [
      {
        path: '',
        name: 'userManagement',
        component: () => import('@/views/Users/UserManagement.vue'),
      },
    ],
  },
  {
    path: "/kapture",
    name: 'kapture',
    component: () => import('@/components/layout/Parent.vue'),
    meta: {
      displayName: "navigation.kapture",
      icon: "$forum",
      groups: ["default", "leader", "admin", "super"]
    },
    children: [
      {
        path: '',
        name: 'manageKaptures',
        component: () => import('../views/Kapture/ManageKaptures.vue'),
      },
      {
        path: "create-kapture",
        name: 'createKapture',
        component: () => import('../views/Kapture/Kapture.vue'),
        meta: {
          displayName: "Create kapture record",
          icon: "$folderAccount",
          groups:  ["default", "leader", "admin", "super"]
        }
      },
      {
        path: ':kaptureId',
        name: '',
        component: () => import('@/components/layout/Parent.vue'),
        props: true,
        children: [
          {
            path: '',
            name: 'viewKapture',
            component: () => import('../views/Kapture/ViewKapture.vue'),
            props: true,
          },
        ]
      }
    ]
  },
  {
    path: "/campaign",
    component: () => import('@/components/layout/Parent.vue'),
    meta: {
      displayName: "navigation.campaigns",
      icon: "$folderAccount",
      groups: ["admin", "super"]
    },
    children: [
      {
        path: '',
        name: 'manageCampaigns',
        component: () => import('../views/Campaign/ManageCampaigns.vue'),
      },
      {
        path: "create-campaign",
        name: 'createCampaign',
        component: () => import('../views/Campaign/CreateCampaign.vue'),
        meta: {
          displayName: "Create campaign",
          icon: "$folderAccount",
          groups: ["admin", "super"]
        }
      },
      {
        path: ':campaignId',
        name: '',
        component: () => import('@/components/layout/Parent.vue'),
        props: true,
        children: [
          {
            path: '',
            name: 'viewCampaign',
            component: () => import('../views/Campaign/ViewCampaign.vue'),
            props: true,

          },
          {
            path: "questions",
            name: 'questions',
            component: () => import('../views/Campaign/QuestionOfTheDay.vue'),
            props: true,
            meta: {
              displayName: "navigation.question",
              icon: "$messageQuestion",
              groups: ["admin", "super"]
            }
          }
        ]
      }
    ]
  },
  {
    path: "/insights",
    component: () => import('@/components/layout/Parent.vue'),
    meta: {
      displayName: "Insights",
      icon: "$lightbulbOn",
      groups: ["admin", "super"]
    },
    children: [
      {
        path: '',
        name: 'insights',
        component: () => import('../views/Insights/Insight.vue'),
        props: true,
      },
    ]
  }
  // {
  //   path: "/administration",
  //   name: 'administration',
  //   component: () => import('../views/Kapture/Kapture.vue'),
  //   meta: {
  //     displayName: "navigation.administration",
  //     icon: "$accountMultiple",
  //     groups: ["admin"]
  //   }
  // },
  
]


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      children: sections
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login.vue'),
      meta: {
        displayName: "Login",
        requiresAuthentication: false
      }
    }
  ]
})

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.replace(to.fullPath);
    }
  }
 
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {
  const store = useAuthenticationStore()
  if (to.name === 'login') {
    next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
  } else if (to.meta && to.meta.requiresAuthentication === false) {
    next() // requires auth is explicitly set to false
  } else if (store.isAuthenticated) {
    next() // i'm logged in. carry on
  } else {
    next({ name: 'login' }) // always put your redirect as the default case
  }
})

export default router
