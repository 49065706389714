// Vuetify
import 'vuetify/styles'
import { createVuetify, type ThemeDefinition } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

import { 
  mdiLightbulbOn,
  mdiAccount,
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAccountOutline,
  mdiAlert,
  mdiArchive,
  mdiArrowLeftDropCircle,
  mdiBackspace,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiEmail,
  mdiEye,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFilterVariant,
  mdiFolderAccount,
  mdiFormatListBulleted,
  mdiForum,
  mdiInformationVariantCircle,
  mdiLogin,
  mdiLogout,
  mdiMagnify,
  mdiMapMarker,
  mdiMenuUp,
  mdiMenuDown,
  mdiMessageQuestion,
  mdiPencil,
  mdiPlayCircle,
  mdiPlus,
  mdiStar,
  mdiStarOutline,
  mdiTrashCanOutline,
  mdiViewDashboard,
  mdiWindowClose
 } from '@mdi/js'

import customSmileyExcellent from '@/components/icons/IconSmileyExcellent.vue'
import customSmileyNeutral from '@/components/icons/IconSmileyNeutral.vue'
import customSmileyPoor from '@/components/icons/IconSmileyPoor.vue'
import customSmileyVeryBad from '@/components/icons/IconSmileyVeryBad.vue'
import customSmileyVeryGood from '@/components/icons/IconSmileyVeryGood.vue'

customSmileyVeryGood
// Import custom scss file
import '@/scss/app.scss'

//Themes
const defaultTheme: ThemeDefinition = {
    dark: false,
    colors: {
      white: '#ffffff',
      'off-white': '#b6bed2', 
      background: '#e6e8ef',
      'bkg-dark': '#04011a',
      primary: '#922582',
      'blue-accent': '#00c2f0',
      secondary: '#281e61',
      
      // App
      'menu-base': '#04011a',
      'menu-state': '#7e0358',
      'menu-hover': '#b366a8',
  
      // Text
      'text-color': '#0A142A',
      'text-link': '#1364c8',
  
      // borders
      'border-color': '#d2dbdb',
  
      // Tables
      'row-odd': '#fefbfd',
      'th-header-border': '#07012c',
      
      // Inputs
      'input-border-colour': '#d2dbdb',
      'input-border-hover': '#bd4697',
      'input-border-active': '#832175',
  
      // Buttons
      //'primary-button-color': '#3877bc',
      'primary-button-color-hover':'#751e68',
      'primary-button-prepend-color': '#832175',
      'primary-button-prepend-border-color': '#a44896',
      
      'secondary-button-color-hover':'#1c1544',
      'secondary-button-prepend-color': '#20184e',
      'secondary-button-prepend-border-color': '#1c1544',
  
      'text-variant-color': '#354F86',
      'text-variant-color-hover': '#275384',
  
      // Tooltips
      'tooltip-default': '#112437',

      // Dialogs
      'dialog-btn-row-background': '#f9f9f9',
  
      // Error styles
      error: '#b8312a',
      'error-bkg': '#cf323b',
      'input-border-error': '#b8312a',
      'input-border-error-hover': '#81221d',

      //Smileys
      'smiley-very-bad': '#bd1e2c',
      'smiley-poor': '#df5166',
      'smiley-neutral': '#faaf40',
      'smiley-very-good': '#8dc53e',
      'smiley-excellent': '#006838',

      // These need sanitised into better values
      'primary-darken-1': '#22694f',
      'primary-darken-2': '194f3b',
          
    },
  }
  

const vuetify = createVuetify({
  locale: {
    locale: 'en',
  },
  date: {
    locale: {
      en: 'en-GB',
    },
  },
    theme: {
      defaultTheme: 'defaultTheme',
      themes: {
        defaultTheme,
      },
    },
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      
      aliases: {
        ...aliases,
        account: mdiAccount,
        accountGroup: mdiAccountGroup,
        accountMultiple: mdiAccountMultiple,
        alert: mdiAlert,
        archive: mdiArchive,
        arrowLeft: mdiArrowLeftDropCircle,
        backspace: mdiBackspace,
        dashboard: mdiViewDashboard,
        delete: mdiDelete,
        chevronDown: mdiChevronDown,
        chevronLeft: mdiChevronLeft,
        chevronRight: mdiChevronRight,
        chevronUp: mdiChevronUp,
        checkCircle :mdiCheckCircleOutline,
        closeCircleOutline: mdiCloseCircleOutline,
        dotsVertical: mdiDotsVertical,
        email: mdiEmail,
        eye: mdiEye,
        eyeOff: mdiEyeOffOutline,
        eyeOn: mdiEyeOutline,
        filter: mdiFilterVariant,
        forum: mdiForum,
        infoCircle: mdiInformationVariantCircle,
        formatList: mdiFormatListBulleted,
        folderAccount: mdiFolderAccount,
        lightbulbOn: mdiLightbulbOn,
        login: mdiLogin,
        logout: mdiLogout,
        magnify: mdiMagnify,
        mapMarker: mdiMapMarker,
        menuUp: mdiMenuUp,
        menuDown: mdiMenuDown,
        messageQuestion: mdiMessageQuestion,
        pencil: mdiPencil,
        playCircle: mdiPlayCircle,
        plus: mdiPlus,
        starOutline: mdiStarOutline,
        star: mdiStar,
        trashCanOutline: mdiTrashCanOutline,
        product: mdiAccountOutline,
        windowClose: mdiWindowClose,
        
        // Custom icons
        smileyExcellent: customSmileyExcellent,
        smileyNeutral: customSmileyNeutral,
        smileyPoor: customSmileyPoor,
        smileyVeryBad: customSmileyVeryBad,
        smileyVeryGood: customSmileyVeryGood,
      },
      sets: {
        mdi,
      },
      
    },
})

export default vuetify;