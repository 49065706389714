<template>
            
<svg version="1.1" class="product-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1079.9 135.2" style="enable-background:new 0 0 1079.9 135.2;" xml:space="preserve" role="img" aria-labelledby="logo_title" aria-describedby="logo_desc">
	 <title id="logo_title">KAPTURE</title>
        <desc id="logo_desc">Logo for KAPTURE</desc>
		<g>
	<path class="KAP0" d="M61.9,57.6l39.9-46.5c1.8-2.1,0.3-5.3-2.4-5.3H77.2c-1.9,0-3.6,0.8-4.8,2.2L32.4,56V9c0-1.8-1.4-3.2-3.2-3.2
		H8.5c-1.8,0-3.2,1.4-3.2,3.2v114.3c0,1.8,1.4,3.2,3.2,3.2h20.7c1.8,0,3.2-1.4,3.2-3.2V91.9l11.9-13.8l31.5,47.1
		c0.6,0.9,1.6,1.4,2.7,1.4h24.9c2.6,0,4.1-2.9,2.7-5.1L61.9,57.6z"/>
	<path class="KAP1" d="M244.3,10.3c-0.9-2.7-3.3-4.5-6.1-4.5h-10.5h-11.9h-9c-2.8,0-5.3,1.8-6.1,4.5l-35.9,112.1
		c-0.7,2.1,0.9,4.2,3.1,4.2h21.2c1.7,0,3.2-1.1,3.7-2.7l29-98.2l29.9,98.2c0.5,1.6,2,2.7,3.7,2.7h21.8c2.2,0,3.7-2.1,3.1-4.2
		L244.3,10.3z"/>
	<path class="KAP2" d="M411.8,11c-7.1-3.4-15.8-5.2-26.2-5.2h-21.7h-15.2H340c-1.8,0-3.2,1.4-3.2,3.2v114.3c0,1.8,1.4,3.2,3.2,3.2
		h20.7c1.8,0,3.2-1.4,3.2-3.2V89.3h21.7c10.4,0,19.1-1.7,26.2-5.2c7.1-3.4,12.4-8.3,15.9-14.6c3.6-6.3,5.3-13.7,5.3-22.1
		s-1.8-15.7-5.3-22C424.1,19.3,418.8,14.5,411.8,11z M400.4,59.8c-3.3,2.8-8.8,4.2-16.4,4.2h-20.1V31.2H384c7.6,0,13,1.4,16.4,4.2
		c3.3,2.8,5,6.8,5,12.1C405.4,52.9,403.7,57,400.4,59.8z"/>
	<path class="KAP3" d="M581.2,5.9h-31.8h-27h-31.8c-1.8,0-3.2,1.4-3.2,3.2V28c0,1.8,1.4,3.2,3.2,3.2h31.8v92.3c0,1.7,1.4,3.1,3.1,3.1
		h20.7c1.7,0,3.1-1.4,3.1-3.1V31.2h31.8c1.8,0,3.2-1.4,3.2-3.2V9.1C584.4,7.3,583,5.9,581.2,5.9z"/>
	<path class="KAP4" d="M745.5,5.9h-20.1c-1.8,0-3.2,1.4-3.2,3.2v69.7c0,7.8-2.1,13.9-6.2,18.2c-4.1,4.3-10.2,6.5-18.1,6.5
		c-7.8,0-13.8-2.2-17.9-6.5c-4.1-4.3-6.1-10.4-6.1-18.2V9.1c0-1.8-1.4-3.2-3.2-3.2h-20.7c-1.8,0-3.2,1.4-3.2,3.2v71.1
		c0,9.8,2,18.4,6,25.7c4,7.4,9.7,13,17.3,17.1c7.6,4,16.8,6,27.6,6c10.9,0,20.1-2,27.7-6c7.6-4,13.4-9.7,17.4-17.1
		c4-7.4,6-15.9,6-25.7V9.1C748.7,7.3,747.3,5.9,745.5,5.9z"/>
	<path class="KAP5" d="M887.7,82.9c5.3-1.8,9.9-4.5,13.6-8c7.7-7.3,11.6-17,11.6-29.1c0-12.1-3.9-21.8-11.6-29
		c-7.7-7.2-18.8-10.9-33.4-10.9h-22.4h-15.2h-8.6c-1.8,0-3.3,1.5-3.3,3.3v114.2c0,1.8,1.5,3.3,3.3,3.3h20.5c1.8,0,3.3-1.5,3.3-3.3
		V85.8h15.6l22.8,37.7c1.2,1.9,3.3,3.1,5.5,3.1H910c2.7,0,4.3-2.9,2.8-5.2L887.7,82.9z M880.8,56.7c-3,2.5-7.8,3.8-14.4,3.8h-20.8
		V31.2h20.8c6.7,0,11.5,1.2,14.4,3.7c3,2.5,4.5,6.1,4.5,10.9C885.2,50.5,883.7,54.2,880.8,56.7z"/>
	<path class="KAP6" d="M1067.9,101.2H1007v-24h52.6c1.7,0,3-1.3,3-3v-18c0-1.7-1.3-3-3-3H1007v-22h60.2c1.7,0,3-1.3,3-3V8.9
		c0-1.7-1.3-3-3-3H1004h-9.2h-11.9c-1.7,0-3,1.3-3,3v114.7c0,1.7,1.3,3,3,3h11.9h9.2h63.9c1.7,0,3-1.3,3-3v-19.4
		C1070.9,102.6,1069.5,101.2,1067.9,101.2z"/>
</g>


</svg>

</template>

<script lang="ts">
    export default {
        name: "product-logo"
    };
</script>

<style scoped>
	.KAP0{fill:#491341;}
	.KAP1{fill:#58164E;}
	.KAP2{fill:#661A5B;}
	.KAP3{fill:#751E68;}
	.KAP4{fill:#832175;}
	.KAP5{fill:#922582;}
	.KAP6{fill:#9D3B8F;}
</style>
