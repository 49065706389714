<template>
            
    <svg version="1.1" class="product-logo-icon"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 55 55" style="enable-background:new 0 0 55 55;" xml:space="preserve" role="img" aria-labelledby="icon_logo_title" aria-describedby="icon_logo_desc">
     <title id="icon_logo_title">KAPTURE</title>
        <desc id="icon_logo_desc">Icon for KAPTURE</desc>
		<path class="KAP7" d="M43,8.5H12.3c-1.6,0-3,1.3-3,3v30.8c0,1.1,0.6,2.1,1.5,2.6l0,0l4,2.3l4,2.3v-4.2V12.9c0-0.4,0.4-0.8,0.8-0.8
	h5.1c0.4,0,0.8,0.3,0.8,0.8v11.5l9.8-11.7c0.3-0.3,0.7-0.6,1.2-0.6h5.4c0.7,0,1,0.8,0.6,1.3l-9.8,11.4l10.8,15.7
	c0.4,0.5,0,1.2-0.7,1.2h-6.1c-0.3,0-0.5-0.1-0.7-0.4l-7.7-11.5l-2.9,3.4v12.1H43c1.6,0,3-1.3,3-3V11.5C46,9.9,44.7,8.5,43,8.5z"/>

    </svg>
    
</template>
    
<script lang="ts">
    export default {
        name: "product-icon-logo"
    };
</script>

<style scoped>
	.KAP7{fill:#007499;}
</style>