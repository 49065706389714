// Maintain alphabetical order from after 'common' values.
// Indent starts new order.

const enGB = {
  language: {
    display: "English (British)",
    locale: "en-GB"
  },
  Value: "Value",
  Where: "Where",
  Operator: "Operator",
	common: {
    actions: 'Actions',
    add: 'Add',
    addFilter: 'Add filter',
    apply: 'Apply',
    archival: 'Archive / Unarchive',
		areYouSure: 'Are you sure?',
    back: 'Back',
    cancel: 'Cancel',
    clear: 'Clear',
    clearFilter: 'Clear filter',
    close: 'Close',
    collapse: 'Collapse',
    complete: 'Complete',
		confirm: 'Confirm',
		continue: 'Continue',
		delete: 'Delete',
		discard: 'Discard',
		edit: 'Edit',
    expand: 'Expand',
    filter: 'Filter',
		functionalityNotReady: 'This functionality is not ready yet',
		help: 'Help',
    Kapture: 'Kapture',
    saveChanges: 'Save changes',
    select: 'Select',
    selectAll: 'Select all',
		view: 'View',
    viewMenuOptions: '*_ View menu options _*',
	},
  campaign: {
    campaignName: 'Campaign name',
    additionalField: 'Enable status dropdown',
    createCampaign: 'Create campaign',
    createYourCampaign: 'Create your campaign',
    saveName: 'Save name',
    updateCampaign: 'Update campaign',
    archival: {
      archive: "Would you like to archive the selected campaign?",
      unarchive: "Would you like to unarchive the selected campaign?",
    },
    errors: {
      campaignMustBeUnique: 'Campaign name must be unique',
    },
    messages: {
      createSuccess: "Successfully created campaign.",
      updateSuccess: "Successfully updated campaign."
    },
    reasons: {
      addMajorReason: 'Add major reason',
      addMinorReason: 'Add minor reason',
      updateMajorReason: 'Edit major reason',
      updateMinorReason: 'Edit minor reason',
      createReason: 'Create reason',
      majorReasons: 'Major reasons',
      minorReasons: 'Minor reasons',
      noMinors: 'No minor reasons added',
      reasonCode: 'Reason code',
      reasons: 'Reasons',
      reasonsCollapsed: 'reasons collapsed',
    }
    
  },
  error: {
    f000: "User with this email address already exists.",
    loginCancel: `<p>Your login attempt was not completed.</p>
    <p>To log in to Kapture, you need to be signed in to your Microsoft 365 account.</p>
    <p>Try again or contact an administrator for help.</p>`
  },
  kapture: {
    customerRef: "Customer reference",
    selectCampaign: "Select campaign",
    selectMajorReason: "Select major reason",
    selectMinorReason: "Select minor reason",
    selectStatus: "Select status",
    messages: {
      createSuccess: "Successfully created kapture record.",
      updateSuccess: "Successfully updated kapture record."
    }
  },
  login: {
    accountLocked: "Account is locked!",
    incorrectDetails: 'Your details are incorrect',
    invalidLogin: "Invalid username or password!",
    invalid2faCode: "Invalid two-factor authentication code!",
  },
  loginPage: {
    buttonLogin: "Sign in",
    caption: "Welcome",
    continueYourJourney: "Continue your journey with Kapture",
    emailPlaceholder: "yourname{'@'}domain.com",
    forgotPasswordLink: "Forgot your password?",
    passwordField: "Password",
    selectLanguage: "Select Language",
    usernameField: "E-mail address",
  },
  navigation: {
    administration: "Administration",
    campaigns: 'Campaigns',
    dashboard: 'Dashboard',
    help: 'Help',
    kapture: "Kapture records",
    logout: 'Logout',
    question: "Question of the day",
    userManagement: 'Admin users',
  },
  passwordChange: {
    cancelButton: "Cancel",
    changePassword: 'Change password',
    enterNewPassword: 'Enter new password',
    failedChange: 'Failed to change user password!',
    passwordChange: 'Password change',
    passwordChangeSuccessTitle: "Your account setup is complete",
    passwordChanged: "You have successfully set up your [ProductName] account.",
    passwordHint: '(Password must be {minLength} characters in length and include {upperCaseCharacters} uppercase letter(s), {lowerCaseCharacters} lowercase letter(s), {specialCharacters} special character(s) and {numericCharacters} number(s))',
    pleaseLogin: "Log in to get started!",
    returnToLoginButton: "Log in", 
  },
  recovery: {
    buttonCloseInvalid: "Close",
    buttonCreatePasswordConfirmed: "Create password",
    captionConfirmed: "Email address confirmed",
    captionInvalid: "Email address not confirmed",
    captionVerifying: "Welcome to [ProductName]",
    messageConfirmed: "Success! Your email address has been confirmed. To complete your account setup, you need to create a password.",
    messageInvalid_p1: "Sorry, it looks like your activation link has expired or you already have an [ProductName] account.",
    messageInvalid_p2: "If your link has expired, you need to contact [ProductName]'s administrator. You can find their contact details in the activation email. If you already have an account, you need to close this window and log in from the home page.",
    messageVerifying: "Your email address is now being confirmed. This will only take a few seconds. Do not close this window.",
  },
  role: {
		Administrator: 'Administrator',
		Author: 'Author',
		Viewer: 'Viewer',
  },
  twoFactorAuth: {
    authCode: "Authenticator code",
    cancelButton: "Cancel",
    cancelTwoFactorCreateButton: "Cancel",
    createTwoFactorAuth: 'Enable two-step verification',
    instructions: "Please use Google Authenticator application on your mobile device and scan the QR code below or enter the code manually. If you do not have the application please visit application store and install application Google Authenticator before proceeding.",
    instructionsP1: "To complete the setup of your [ProductName] account, you need to enable two-step verification. You can do that by adding [ProductName] to Google Authenticator on your mobile device using the QR code or the setup key provided below.",
    instructionsP2: "After you have set up two-step verification you can log in to [ProductName] using your password and a code provided by Google Authenticator.",
    instructionsP3: "If you don`t have Google Authenticator, download it to your mobile device now from Google Play or the App Store.",
    twoFactorAuth: 'Two-step verification',
    setupKey: "Setup key",
    sixDigitCode: "Enter your Google Authenticator 6-digit code:",
    verifictionCode: "Please enter 6-digit code from Authenticator application",
    verifyCode: 'Verify code',
  },
  user: {
    create: {
			emailAddress: 'Email address',
			firstName: 'First name',
			inviteUser: 'Invite user',
			knownAs: 'Known as',
			lastName: 'Last name',
			selectRole: 'Select role',
			title: 'Create user',
		},
    edit: {
      title: 'Edit user',
    },
    delete: {
      title: 'Delete user',
    },
		home: {
			activationLinkExpired: 'Activation link expired',
			assignRoles: 'Assign roles',
			backToDashboard: 'Back to dashboard',
			bulkActions: 'Bulk actions',
			columns: {
        actions: 'Actions',
				email: 'Email',
				knownAs: 'Known as',
				lastActive: 'Last active',
				name: 'Name',
				role: 'Role',
			},
			createNewUser: 'New user',
			deleteUserMessage: 'You are about to delete the user with this registered email address {emailAddress}',
			existingUsers: 'Existing users',
			myProfile: 'My profile',
			resendInvite: 'Resend invite',
			searchUsers: 'Search users',
			title: 'Manage admin users',
			unregistered: 'Unregistered',
		},
    myProfile: {
      title: 'My Profile'
    },
    resendInvite: {
      title: 'Re-invite user',
      message: 'Send invite to the following email?'
    },
		roles: {
			administrator: 'Administrators ({count})',
			all: 'All ({count})',
			author: 'Authors ({count})',
			viewer: 'Viewers ({count})',
		},
	},
  validation: {
    isNotValid: '{name} is not valid',
    isRequired: '{name} is required',
    shouldBeEqual: '{name} should be equals {equalName}',
    shouldBeMax: '{name} should be less than {max} characters',
    shouldBeMin: '{name} should be more than {min} characters',
    shouldBeMinMax: '{name} should be between {min} and {max}',
    password: {
      lowerCaseCharacters: '{name} must include {lowerCaseCharacters} lowercase letter(s)',
      minLength: '{name} must be {minLength} characters in length',
      numericCharacters: '{name} must include {numericCharacters} number(s)',
      specialCharacters: '{name} must include {specialCharacters} special character(s)',
      upperCaseCharacters: '{name} must include {upperCaseCharacters} uppercase letter(s)',
    },
  }, 
}

export default enGB;
