<template>

    <header class="primary-header">
      <div class="primary-header__container">
        
        <div class="app-logo-holder--mobile">
          <span class="sr-only">KAPTURE</span>
            <product-logo />
        </div>

        <div class="app-logo-holder--desktop">
          <span class="sr-only">KAPTURE</span>
            <product-icon-logo />
        </div>

        <button
          class="sidenav-trigger"
          v-on:click="navOpen=!navOpen" 
          v-bind:class="{active:navOpen}"
          aria-label="Navigation Button"
          >
          <span class="hb hb--top"></span>
          <span class="hb hb--mid"></span>
          <span class="hb hb--bottom"></span>
        </button>

      </div>
        
      <div 
        class="sidemenu-wrapper"
        :class="[navOpen ? 'nav-open' : 'nav-closed']"
        >
        <nav 
          class="primary-nav"
          >
          <ul class="primary-nav__list">

            <navigation-tab 
              v-for="route in allowedRoutes" 
              :key="route.name"
              @navOpen="val => { navOpen = val;}"
              :navOpen="navOpen"
              :route="route"
            />

            <li class="primary-nav__item">
              <a
                @click="openHelpPanel"
                @keyup.enter="openHelpPanel" 
                class="primary-nav__link"
                tabindex="0"
                >
                <v-icon
                  icon="$infoCircle"
                  class="primary-nav__icon"
                  />
                  <span class="primary-nav__text">{{ $t("navigation.help") }}</span>
                </a>
            </li>
            
            <li class="primary-nav__item">
              <a
                @click="logoutAction"
                class="primary-nav__link"
                tabindex="0"
                >
                <v-icon
                  icon="$logout"
                  class="primary-nav__icon"
                  />
                  <span class="primary-nav__text">{{ $t("navigation.logout") }}</span>
                </a>
            </li>
            
          </ul>
        </nav>
      </div>

      <div class="desktopnav-wrapper">

        <nav 
          class="primary-nav"
          >
          <ul class="primary-nav__list">

            <navigation-tab
              v-for="route in allowedRoutes"
              :key="route.name"
              :route="route"
              :screenReaderOnly="true"
            />
            <li class="primary-nav__item">
              <a
                @click="openHelpPanel"
                @keyup.enter="openHelpPanel" 
                class="primary-nav__link"
                tabindex="0"
                >
                <v-icon
                  icon="$infoCircle"
                  class="primary-nav__icon"
                  />
                  <span class="primary-nav__text sr-only">{{ $t("navigation.help") }}</span>
                  <v-tooltip
                    activator="parent"
                    location="start"
                    class="i-tooltip i-tooltip--left"
                    :title="$t('navigation.logout')"
                  >{{ $t("navigation.help") }}</v-tooltip>
                </a>
            </li>
            <li class="primary-nav__item">
              <a
                @click="logoutAction"
                @keyup.enter="logoutAction" 
                class="primary-nav__link"
                tabindex="0"
                >
                <v-icon
                  icon="$logout"
                  class="primary-nav__icon"
                  />
                  <span class="primary-nav__text sr-only">{{ $t("navigation.logout") }}</span>
                  <v-tooltip
                    activator="parent"
                    location="start"
                    class="i-tooltip i-tooltip--left"
                    :title="$t('navigation.logout')"
                  >{{ $t("navigation.logout") }}</v-tooltip>
                </a>
            </li>
          </ul>
        </nav>
      
      </div>

    </header>


</template>

<script lang="ts">
import ProductLogo from '@/components/shared/ProductLogoComponent.vue';
import ProductIconLogo from '@/components/shared/ProductIconLogoComponent.vue';
import { useAuthenticationStore } from '@/stores/authentication';
import NavigationTab from './NavigationTab.vue';

import {sections} from '@/router';

export default {
  name: "primary-header",
  props: [],
  components: {
      ProductLogo,
      ProductIconLogo,  
      NavigationTab
  },
  methods: {
    async logoutAction(event: Event) {
      try {
        this.authStore.logOut();
        this.$router.push({name: 'login'})
      } finally {
        window.location.reload();
        
      }
      //this.$router.push({ path: '/login' });
      event.preventDefault()
    },
    openHelpPanel() {       
      this.$emit("helpOpen")
      setTimeout(()=> {   
          const elem = document.getElementById("side-panel-close");
          elem?.focus();
      }, 1000); 
    },
    
    // returnFocus() {      
    //   const elem = document.getElementById("filterToggle");
    //   elem?.focus();
    // },
  },
  computed: {
    allowedRoutes() {
      return this.routes.filter((route) => route.meta.groups.length == 0 || route.meta.groups.includes(this.userGroup))
    }
  },
  data () {
    return {
      navOpen: false,
      routes: sections,
      userGroup: useAuthenticationStore().getUserGroup as string
    }
  },
  setup() {
    const authStore = useAuthenticationStore();

    return {
      authStore,
    }
  },
}
</script>

<style lang="scss">
    
</style>
