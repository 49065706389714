const settings = {
  _gatewayUrl: "VITE_APP_GATEWAY_PLACEHOLDER",
  _authenticationUrl: "VITE_APP_AUTHENTICATION_PLACEHOLDER",
  _applicationId: 'f8723e8e-a2f0-481b-bc38-4dee9210863a',

  getGatewayUrl: (): string => {
    if (settings._gatewayUrl.startsWith("VITE_APP")) {
      // Fetch from environment //
      settings._gatewayUrl = import.meta.env.VITE_APP_GATEWAY;
    }
    return settings._gatewayUrl;
  },

  getAuthenticationUrl: (): string => {
    if (settings._authenticationUrl.startsWith("VITE_APP")) {
      // Fetch from environment //
      settings._authenticationUrl = import.meta.env.VITE_APP_AUTHENTICATION;
    }
    return settings._authenticationUrl;
  },

  getApplicationId:(): string => {

      var appId = settings._applicationId;
      if (settings._applicationId.startsWith('VITE_APP') || settings._applicationId === '')
          appId = import.meta.env.VITE_APP_APPLICATION_ID;
          
      // appId = "f8723e8e-a2f0-481b-bc38-4dee9210863a"

      return appId;
  }
}

export default settings;
