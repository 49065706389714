import { useAuthenticationStore } from "@/stores/authentication";
import settings from "../settings/env-settings";
import { User } from "./domain/User";

export default new class Api {
    baseApiUrl: string = settings.getGatewayUrl();
    authApiUrl: string = settings.getAuthenticationUrl()

    getApiEndpoint = (url: string): string => {return `${this.baseApiUrl}${url}`;}
    getAuthEndpoint = (url: string): string => `${this.authApiUrl}${url}`;

    getBaseRequestOptions = (abortSignal?: AbortSignal): RequestInit => {
        const options: RequestInit = {
            headers: {
            },
            method: "GET",
            mode: 'cors',
        };
    
        if (abortSignal) {
            options.signal = abortSignal;
        }
    
      const lc_securityStore = useAuthenticationStore();
      const token = lc_securityStore.token;
    
        if (token !== null && token !== undefined && token !== "") {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            };
        }
    
        return options;
    };
    
    getPOSTRequestOptions = (body: any): RequestInit => {
        const options = this.getBaseRequestOptions();
    
        return {
            ...options,
            method: 'POST',
            headers: {
                ...options.headers,
          "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };
    };
    
    getPUTRequestOptions = (body: any): RequestInit => {
      const options = this.getBaseRequestOptions();
    
      return {
        ...options,
        method: 'PUT',
        headers: {
          ...options.headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
    };
    
    getDELETERequestOptions = (body: any): RequestInit => {
      const options = this.getBaseRequestOptions();
    
      return {
        ...options,
        method: 'DELETE',
        headers: {
          ...options.headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };
    };

    DeleteAsync = async (url: string, body: any): Promise<any> => {
        const endpoint = this.getApiEndpoint(url);
        
        const reqOptions = this.getDELETERequestOptions(body);
        return fetch(endpoint, reqOptions);
    }

    PostAsync = async (url: string, body: any, onError: any = null): Promise<any> => {
      const endpoint = this.getApiEndpoint(url);
      const reqOptions = this.getPOSTRequestOptions(body);
      return fetch(endpoint, reqOptions).catch(onError);
    }

    PutAsync = async (url: string, body: any): Promise<any> => {
        const endpoint = this.getApiEndpoint(url);
        
        const reqOptions = this.getPUTRequestOptions(body);
        return fetch(endpoint, reqOptions);
    }

    GetAsync = async (url: string, body: any): Promise<any> => {
        const endpoint = this.getApiEndpoint(url);
        
        const reqOptions = this.getBaseRequestOptions();

        return fetch(endpoint, reqOptions);
    }

    // Sign-in user //
    async signin(o365token: any) {
      var requestData = {
        token: o365token
      };

      return new Promise((resolve) => {
        this.PostAsync("Auth/AuthenticateUser", requestData, () => {resolve(null)}).then((res) => res.json()).then(response => {
          if (response.authenticated === true) {
            resolve(response);
          } else {
            resolve(null);
          }
        }).catch(() => { resolve(null); })
      });
    }
}