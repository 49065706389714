<template>
    <div>
        <div 
            class="side-panel-underlay"
            :class="open ? 'side-panel--open' : 'side-panel--closed'"
            >
        </div>
            <aside
                id="side-panel"
                class="side-panel help-panel"
                :class="open ? 'side-panel--open' : 'side-panel--closed'"
                tabindex="-1"
                >
                
                <div class="help-panel__viewport">
                    
                    <header class="help-panel-header">
                        <h2 class="help-panel-header__section-title">
                            Help
                        </h2>
                    
                        <div class="help-panel-header__btn-close">
                            <v-btn
                                @click="$emit('close')"
                                id="side-panel-close"
                                type="submit"
                                density="compact"             
                                icon
                                class="i-btn i-btn--filter-close" 
                                ref="sidePanelClose"
                                ><span class="sr-only">{{ $t('common.close') }}</span>
                                <v-icon icon="$closeCircleOutline"></v-icon>
                            </v-btn>    
                        </div>
            
                    </header>
                    
                    <object 
                        type="text/html" 
                        data="/help/kapture-overview.htm"
                        class="help-content-wrap"
                    ></object>

                </div>

            </aside>  
    </div>
</template>

<script lang="ts">

export default {

    props: [
        "open"
    ],
    
}
</script>