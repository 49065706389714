<template>
  <div>
    
    <navigation-bar
      @helpOpen="openHelpPanel"
    />  
    <v-main>
      <RouterView/>
      <HelpPanel
          :open="helpOpen" 
          @close="closeHelpPanel"
      />
    </v-main>

  </div>
</template>

<script lang="ts">

import NavigationBar from '@/components/layout/navigation/NavigationBar.vue';
import HelpPanel from '@/components/shared/HelpPanel.vue';

export default {
  components: {NavigationBar, HelpPanel},
  methods: {
    openHelpPanel() {       
        this.helpOpen = true
    },
    closeHelpPanel() { 
      this.helpOpen = false
    }
  },
  data() {
    return {
      helpOpen: false,
    }
  }
}
</script>

