const en = {
  language: {
    display: "English Dummy",
    locale: "en"
  },
	common: {
		continue: '*_ Continue _*',
		edit: '*_ Edit _*',
		delete: '*_ Delete _*',
		view: '*_ View _*',
		select: '*_ Select _*',
		confirm: '*_ Confirm _*',
		discard: '*_ Discard _*',
		areYouSure: '*_ Are you sure? _*',
		functionalityNotReady: '*_ This functionality is not ready yet _*',
    viewMenuOptions: '*_ View menu options _*',
	},
  loginPage: {
    buttonLogin: "*_ Sign in _*",
    caption: "*_ Welcome _*",
    continueYourJourney: "*_ Continue your journey with Script IT _*",
    forgotPasswordLink: "*_ Forgot your password? _*",
    passwordField: "*_ Password _*",
    usernameField: "*_ E-mail address _*",
    selectLanguage: "*_ Select Language _*",
    emailPlaceholder: "*_ yourname{'@'}domain.com _*"
  },
  login: {
    accountLocked: "*_ Account is locked! _*",
    incorrectDetails: '*_ Your details are incorrect _*',
    invalidLogin: "*_ Invalid username or password! _*",
    invalid2faCode: "*_ Invalid two-factor authentication code! _*",
  },
  navigation: {
    dashboard: '*_ Dashboard _*',
    logout: '*_ Logout _*',
    userManagement: '*_ User managment _*',
    question: "*_ Question of the day _*"
  },
  passwordChange: {
    passwordChange: '*_ Password change _*',
    changePassword: '*_ Change password _*',
    enterNewPassword: '*_ Enter neew password _*',
    passwordHint: '*_ (Password must be {minLength} characters in length and include {upperCaseCharacters} uppercase letter(s), {lowerCaseCharacters} lowercase letter(s), {specialCharacters} special character(s) and {numericCharacters} number(s)) _*',
    failedChange: '*_ Failed to change user password! _*',
    passwordChangeSuccessTitle: "*_ Your account setup is complete _*",
    passwordChanged: "*_ You have successfully set up your [ProductName] account. _*",
    pleaseLogin: "*_ Log in to get started! _*",
    returnToLoginButton: "*_ Log in _*",
    cancelButton: "*_ Cancel _*"
  },
  validation: {
    isRequired: '*_ {name} is required _*',
    isNotValid: '*_ {name} is not valid _*',
    shouldBeMinMax: '*_ {name} should be between {min} and {max} _*',
    shouldBeMin: '*_ {name} should be more than {min} characters _*',
    shouldBeMax: '*_ {name} should be less than {max} characters _*',
    shouldBeEqual: '*_ {name} should be equals {equalName} _*',
    password: {
      minLength: '*_ {name} must be {minLength} characters in length _*',
      upperCaseCharacters: '*_ {name} must include {upperCaseCharacters} uppercase letter(s) _*',
      lowerCaseCharacters: '*_ {name} must include {lowerCaseCharacters} lowercase letter(s) _*',
      numericCharacters: '*_ {name} must include {numericCharacters} number(s) _*',
      specialCharacters: '*_ {name} must include {specialCharacters} special character(s) _*',
    }
  },
  twoFactorAuth: {
    twoFactorAuth: '*_ Two-step verification _*',
    verifyCode: '*_ Verify code _*',
    sixDigitCode: "*_ Enter your Google Authenticator 6-digit code: _*",
    verifictionCode: "*_ Please enter 6-digit code from Authenticator application _*",
    createTwoFactorAuth: '*_ Enable two-step verification _*',
    instructions: "*_ Please use Google Authenticator application on your mobile device and scan the QR code below or enter the code manually. If you do not have the application please visit application store and install application Google Authenticator before proceeding. _*",
    instructionsP1: "*_ To complete the setup of your [ProductName] account, you need to enable two-step verification. You can do that by adding [ProductName] to Google Authenticator on your mobile device using the QR code or the setup key provided below. _*",
    instructionsP2: "*_ After you have set up two-step verification you can log in to [ProductName] using your password and a code provided by Google Authenticator. _*",
    instructionsP3: "*_ If you don`t have Google Authenticator, download it to your mobile device now from Google Play or the App Store. _*",
    cancelTwoFactorCreateButton: "*_ Cancel _*",
    cancelButton: "*_ Cancel _*",
    setupKey: "*_ Setup key _*",
    authCode: "*_ Authenticator code _*"
  },
	user: {
		home: {
			backToDashboard: '*_ Back to dashboard _*',
			title: '*_ User Management _*',
			createNewUser: '*_ New user _*',
			myProfile: '*_ My profile _*',
			existingUsers: '*_ Existing users _*',
			bulkActions: '*_ Bulk actions _*',
			assignRoles: '*_ Assign roles _*',
			searchUsers: '*_ Search users _*',
			deleteUserMessage: '*_ You are about to delete the user with this registered email address {emailAddress} _*',
			resendInvite: '*_ Resend invite _*',
			unregistered: '*_ Unregistered _*',
			activationLinkExpired: '*_ Activation link expired _*',
			columns: {
				knownAs: '*_ Known as _*',
				name: '*_ Name _*',
				email: '*_ Email _*',
				role: '*_ Role _*',
				lastActive: '*_ Last active _*',
        actions: '*_ Actions _*',
			}
		},
		roles: {
			all: '*_ All ({count}) _*',
			administrator: '*_ Administrators ({count}) _*',
			author: '*_ Authors ({count}) _*',
			viewer: '*_ Viewers ({count}) _*'
		},
		myProfile: {
			title: '*_ My Profile _*'
		},
		create: {
			firstName: '*_ First name _*',
			lastName: '*_ Last name _*',
			knownAs: '*_ Known as _*',
			emailAddress: '*_ Email address _*',
			selectRole: '*_ Select role _*',
			inviteUser: '*_ Invite user _*',
			title: '*_ Create user _*'
		},
    edit: {
      title: '*_ Edit user _*',
    },
    delete: {
      title: '*_ Delete user _*',
    },
    resendInvite: {
      title: '*_ Re-invite user _*',
      message: '*_ Send invite to the following email? _*'
    }
	},
	role: {
		Administrator: '*_ Administrator _*',
		Author: '*_ Author _*',
		Viewer: '*_ Viewer _*'
  },
  recovery: {
    captionVerifying: "*_ Welcome to [ProductName] _*",
    messageVerifying: "*_ Your email address is now being confirmed. This will only take a few seconds. Do not close this window. _*",

    captionConfirmed: "*_ Email address confirmed _*",
    messageConfirmed: "*_ Success! Your email address has been confirmed. To complete your account setup, you need to create a password. _*",
    buttonCreatePasswordConfirmed: "*_ Create password _*",

    captionInvalid: "*_ Email address not confirmed _*",
    messageInvalid_p1: "*_ Sorry, it looks like your activation link has expired or you already have an [ProductName] account. _*",
    messageInvalid_p2: "*_ If your link has expired, you need to contact [ProductName]'s administrator. You can find their contact details in the activation email. If you already have an account, you need to close this window and log in from the home page. _*",
    buttonCloseInvalid: "*_ Close _*"
  },
  error: {
    f000: "*_ User with this email address already exists. _*"
  }
}
export default en
