export class User {
    username: string;
    group: string;
    department: string;
    timezone: string;

    constructor(username: string, group: string, department: string, timezone: string) {
        this.username = username
        this.group = group;
        this.department = department
        this.timezone = timezone
    }
}