import { defineStore } from 'pinia'
import { PublicClientApplication } from '@azure/msal-browser';
import { User } from '@/common/api/domain/User';
import settings from '../common/settings/env-settings'
import { mdiCompassOutline } from '@mdi/js';
import Api from '@/common/api/Api';

export const useAuthenticationStore = defineStore({
  id: 'authentication',
  state: () => ({
    user: null as User | null,
    token: null as string | null,
    officeToken: null as string | null,
    msalConfig: {
			auth: {
				clientId: settings.getApplicationId(),
				authority: 'https://login.microsoftonline.com/common',
				redirectUri: "http://localhost:5173/"
      }
    }
  }),
  getters: {
    isAuthenticated: (state) => {
      return state.user != null
    },
    getUserGroup: (state) => {
      return state.user?.group
    },
    getToken: (state) => {
      return state.token
    },
    getTimezone: (state) => {
      var timeZone = state.user?.timezone
      if(timeZone == null || timeZone.includes("GMT")) {
        return "Europe/London"
      }
      return "Africa/Johannesburg"
    }
  }, 
  actions: {
    async getOffice365Token() {
      let request = {
				scopes: ["User.Read"],
			};

			// Build redirect path //
			var prt = window.location.protocol;
			if (!prt.endsWith(":")) prt = prt + ":";
			var hst = prt + "//" + window.location.host;
			if (!hst.endsWith("/")) hst = hst + "/";
			// hst = hst + "blank.html";

			this.msalConfig.auth.redirectUri = hst;

      const msalInstance = new PublicClientApplication(
				this.msalConfig,
			);

      try {
				await msalInstance.initialize();
        
				let tokenResponse = await msalInstance.acquireTokenPopup(request);
        this.officeToken = tokenResponse.accessToken
        
        return tokenResponse.accessToken
      } catch (error) {
        throw error;
      }

    },
    async logIn(successCallback: any, failureCallback: any) {
      try {
				var response = await Api.signin(this.officeToken) as any;
        if(response != null) {
          this.user = new User(response.username, response.group, response.department, response.timezone)
          this.token = response.token
          successCallback()
        } else {
          failureCallback()
        }
      } catch(error) {
        failureCallback(error)
      }
    },
    async logOut() {
      this.user = null
    }
  },
  persist: false
})
